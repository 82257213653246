<script>
import { mapStores } from 'pinia'

import { useAccountStore } from '@/stores'
import liffapi from '@/liffapi.js'
import utils from '@/utils.js'

export default {
  name: 'ProtectRegister',

  components: {},

  props: {},

  data: () => ({
    isDebug: import.meta.env.VITE_DEBUG == 'true',
    isLoading: false,

    report: null,

    form: {
      key: null,
      imei: null,
      tagName: null,
      isLostMode: false,
      lostMessage: null,
    },
  }),

  computed: {
    ...mapStores(useAccountStore),

    isFormDiff() {
      let imeiDiff = this.report?.imei && this.form.imei != this.report.imei
      return Boolean(
        this.form.isLostMode != this.report.isLostMode ||
          this.form.lostMessage != this.report.lostMessage ||
          imeiDiff
      )
    },

    ok() {
      return Boolean(this.isFormDiff && this.form?.key)
    },
  },

  mounted() {
    this.isLoading = true
    let key = this.$route.params.key
    liffapi.protect
      .get(key)
      .then((response) => {
        this.report = response.data.entity
      })
      .catch((err) => {
        console.error(err)
        if (err.status == 401) {
          this.$router.push('/login')
        }
      })
      .finally(() => {
        this.isLoading = false
      })
  },

  methods: {
    submit(form) {
      if (!this.ok) return

      this.isLoading = true
      this.account
        .updateProtect(form)
        .then((response) => {
          utils.toast(response.message, 'success')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<template>
  <div v-if="report" class="content account-view">
    <nav class="mb-6" aria-label="">
      <o-button tag="router-link" to="/" icon-left="arrow-left"> </o-button>
      <p>{{ report.category }}</p>
    </nav>

    <section>
      <h2>Add new tag</h2>

      <figure class="has-text-centered">
        <img :src="report.artUrl" style="max-height: 4rem" />
        <p>{{ report.encodedId }}</p>
      </figure>

      <template v-if="report.state == 'unregistered'">
        <div class="is-flex">
          <div class="column has-text-left" style="flex-grow: 2">
            <o-field label="Tag Name">
              <o-input v-model="form.tagName" placeholder="e.g. Keys">
              </o-input>
              <template #message>
                <p>Identitfy the item you're tagging</p>
              </template>
            </o-field>
          </div>
        </div>

        <div class="has-text-centered">
          <o-button
            variant="primary"
            :disabled="!ok || isLoading"
            :loading="isLoading"
            @click="submit(form)"
          >
            Add to account
          </o-button>
        </div>
      </template>

      <o-notification v-else variant="danger">
        This tag is already registered. If it is yours try editing it from your
        account.
      </o-notification>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.protect-content {
  margin: 0 1rem 0 1rem;
}

.report {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.friend {
  display: flex;
  justify-content: space-between;
}
</style>
