import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import rollbar from '@/rollbar.js'
import polyfills from '@/polyfills.js'
import LiffModal from '@/components/Modal'

import Oruga from '@oruga-ui/oruga-next'
import { bulmaConfig } from '@oruga-ui/theme-bulma'

import App from './App.vue'
import gtag from '@/gtag.js'
import router from './router'

import '@/assets/base.scss'

polyfills.apply()

const pinia = createPinia()
pinia.use(({ store }) => {
  store.$router = markRaw(router)
})

const app = createApp(App).use(Oruga, bulmaConfig)

app.use(LiffModal)
app.use(pinia)
app.use(router)
gtag.setup(app, router, 'accounts')

app.config.errorHandler = (err, vm, info) => {
  rollbar.error(err)
  throw err // rethrow
}
app.config.globalProperties.$rollbar = rollbar

app.mount('#account-app')
